import { changeAcquisitionStatus, clientDynamicStatusesByKey } from 'api/clients';
import classNames from 'classnames/bind';
import Button from 'components/ui/Button/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import SelectComponent from 'components/ui/Select/Select';
import { USER_SELECT_FILTER_DEFAULT_VALUE } from 'constants/clients/filters.const';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Popup from 'reactjs-popup';
import { firstLetterUppercase } from 'utils/firstLetterUppercase';
import PaginateSelect from '../../../../../components/ui/PaginateSelect';
import {
  fetchDesksForType,
  fetchTeamsForDesk,
} from '../../../../../utils/managment/fetchData';
import styles from '../SalesBulkActionsPopup/bulk-actions-popup.module.scss';
import { notify } from '../../../../../utils/notify';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { BULK_ACTIONS_TYPE } from '../../../../../constants/clients/bulk-actions.const';

const cx = classNames.bind(styles);

interface IProps {
  trigger: JSX.Element;
  bulkActionsData: number[];
  bulkActionsType: string;
  onCloseModal?: () => void;
  selectedClients: string[];
}


const ChangeAcquisitionStatuses: FC<IProps> = ({
                                                 trigger,
                                                 bulkActionsData,
                                                 bulkActionsType,
                                                 selectedClients,
                                                 onCloseModal,
                                               }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [dynamicFilter, setDynamicFilter] = useState(null);

  const currentLang = localStorage.getItem('lang');
  const componentMounted = useRef(true);

  const setDefaultValues = () => {
    return {
      desk_id: null,
      team_id: USER_SELECT_FILTER_DEFAULT_VALUE[0],
      status: null,
    };
  };
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const closeModal = (closeModal) => {
    reset(setDefaultValues());
    closeModal();
    onCloseModal?.();
  };

  const onSubmit = async (close, data) => {

    const checkClientsSales = selectedClients.every(item => item === BULK_ACTIONS_TYPE.SALES);
    const checkClientsRetention = selectedClients.every(item => item === BULK_ACTIONS_TYPE.RETENTION);

    const checkClientsForRetention = selectedClients.includes(BULK_ACTIONS_TYPE.RETENTION);
    const checkClientsForSales = selectedClients.includes(BULK_ACTIONS_TYPE.SALES);

    const errorMessage = bulkActionsType === BULK_ACTIONS_TYPE.SALES
      ? 'The client belongs to the department RETENTION, you must use the "Change Acquisition Status Retention" button to change the status!'
      : 'The client belongs to the department SALES, you must use the "Change Acquisition Status Sales" button to change the status!';

    if (!checkClientsSales && bulkActionsData?.length > 1 && bulkActionsType === BULK_ACTIONS_TYPE.SALES ||
      !checkClientsRetention && bulkActionsData?.length > 1 && bulkActionsType === BULK_ACTIONS_TYPE.RETENTION) {
      notify({
        type: 'error',
        message: 'It is impossible to change the status! It is necessary to select clients and press button "Change Acquisition Statuses" belonging to the same department!',
        timeOut: 3000,
      });
      return;
    }


    if (checkClientsForRetention && bulkActionsType === BULK_ACTIONS_TYPE.SALES) {
      notify({
        type: 'error',
        message: errorMessage,
        timeOut: 3000,
      });
      return;
    }

    if (checkClientsForSales && bulkActionsType === BULK_ACTIONS_TYPE.RETENTION) {
      notify({
        type: 'error',
        message: errorMessage,
        timeOut: 3000,
      });
      return;
    }


    setIsLoading(true);

    const postData = {
      bulk_type: bulkActionsType,
      statusId: getValue(data['status']),
      user_ids: bulkActionsData,
    };

    changeAcquisitionStatus(clearEmptyField(postData))
      .then((res) => {
        if (res.status === 200 || res.status === 202) {
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);
  const onOpen = () => {
    fetchClientDynamicStatuses();
  };
  const setDefaultValuesTeam = () => {
    return {
      bulk_type: bulkActionsType,
      desk_id: getValues('desk_id'),
      team_id: '',
      statusId: getValues('status'),
      user_ids: bulkActionsData,
    };
  };
  const setDefaultValuesOperator = () => {
    return {
      bulk_type: bulkActionsType,
      desk_id: getValues('desk_id'),
      team_id: getValues('team_id'),
      statusId: getValues('status'),
      user_ids: bulkActionsData,
    };
  };

  const fetchClientDynamicStatuses = () => {
    clientDynamicStatusesByKey(bulkActionsType).then((res) => {
      if (componentMounted.current) {
        const filter = res.data.data;

        setDynamicFilter({
          value: filter.key,
          label: filter.translate,
          options: filter.activeStatuses.map((item) => ({
            value: item.id,
            label: item.translate,
          })),
        });
      }
    });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyField = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  return (
    <>
      <Popup
        modal
        trigger={trigger}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              <div className={cx('popup-title')}>
                <span>{`Bulk Actions - ${firstLetterUppercase(
                  bulkActionsType,
                  currentLang,
                )}`}</span>
                <span>{bulkActionsData.length} Clients selected</span>
              </div>
            }
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-title')}></div>
                <div className={cx('content-form')}>
                  <form style={{ minHeight: 200 }} onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12}>
                          {dynamicFilter && (
                            <SelectComponent
                              label={dynamicFilter.label}
                              control={control}
                              id={`${bulkActionsType}-status`}
                              name='status'
                              options={dynamicFilter.options}
                              rules={{ required: ' Field is required' }}
                              errors={errors.status}
                            />
                          )}
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText='Cancel'
                          buttonType='outline'
                          size='big'
                          type='button'
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <LoaderButton
                          buttonType='primary'
                          size='big'
                          showSpinner={isLoading}
                          type='submit'
                          onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                          buttonText={'Apply changes'}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default ChangeAcquisitionStatuses;
